import React, { useState, useEffect } from 'react';
import { Button, LoadingIcon } from 'prosolve-common-components-ui'
import useFetch from '../../hooks/useFetch';
import styled from 'styled-components';
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const Table = styled.table`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  color: #080808;
  font-size: 14px;
  border-spacing: 0;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  cursor: pointer;
  &:last-child td {
    border-bottom: none;
  }
`;

const TableColumn = styled.td`
  padding: 8px 20px;
  border-bottom: 1px solid #c4c4c4;
`;

export default () => {
  const fetch = useFetch();
  const navigate = useNavigate();

  const adminPermissionsCookies = Cookies.get('adminPermissions') || '';
  const isReadOnly = !(adminPermissionsCookies.indexOf('ASSESSMENT_BUILDER_EDIT') > -1);

  const [isLoading, setIsLoading] = useState(true)
  const [assessmentNames, setAssessmentNames] = useState([]);

  const fetchAssessmentNames = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`assessment/builder/list`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAssessmentNames([])
      } else {
        setAssessmentNames(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAssessmentNames([])
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAssessmentNames()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addEditAssessment = async (assessmentName) => {
    navigate(`${assessmentName}`);
  }

  return (
    isLoading ?
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <LoadingIcon />
    </div>
    :

    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '20px'
    }}>

      <Table>
        <TableBody>
          {
            assessmentNames
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ name = '' }) => {
              return (
                <TableRow key={name} onClick={() => addEditAssessment(name)}>
                  <TableColumn>
                    {name}
                  </TableColumn>
                  <TableColumn style={{ textAlign: 'right', color: '#0280c6' }}>
                    <FaArrowRight style={{ fontSize: '22px' }}  />
                  </TableColumn>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    
      {/* <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '150px' }}>Select Assessment:</div>
        <SelectDropdown
          id='select-assessment'
          options={assessmentNameOptions}
          onChange={(val) => setSelectedAssessmentName(val)}
        />
      </div> */}

      <div style={{ display: 'flex', alignItems: 'center', marginTop: '40px', paddingBottom: '20px' }}>
        <Button
          style={{ width: '250px' }}
          $colored={true}
          $disabled={isReadOnly}
          onClick={() => {
            if (!isReadOnly) {
              addEditAssessment('new')
            }
          }}
        >
          {'Create New Assessment'}
        </Button>
      </div>
    </div>
  )
}