import React from 'react';
import { useGlobalData } from '../GlobalData';
import { Main, MainContent, Section, Footer, FadeInWrapper } from 'prosolve-common-components-ui'
import ProsolveLogoSlim from '../images/prosolve-logo-slim.png';
import AccountSelector from './AccountSelector';

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default () => {
  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  // Main code

  return (
    <Main $isMobileView={isMobileView} 
    $backgroundColor={'#ffffff'}
    >
      <MainContent $isMobileView={isMobileView}>
        <div style={{ padding: '0px' }}>
          <FadeInWrapper>
            <Section style={{ padding: '0px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <img src={ProsolveLogoSlim} style={{ height: '55px', marginLeft: '-10px', marginRight: '10px' }} alt="prosolveLogo"/>
                <div style={{
                  fontSize: '28px',
                  fontWeight: 'bold'
                }}>
                  {'Shipment Tracker'}
                </div>
              </div>
              <div>
                <AccountSelector />
              </div>
            </Section>
          </FadeInWrapper>
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
  )
}
