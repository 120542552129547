import React, { useState, useEffect } from 'react';
import BarChart from './BarChart';
import QuestionSelector from './QuestionSelector';
import TextAnswersGrid from './TextAnswersGrid';
import { LoadingIcon } from 'prosolve-common-components-ui'

export default ({
  isLoading,
  selectedAssessmentName,
  analyticsData = {},
  isPercentage
}) => {

  const {
    counts,
    questions = []
  } = analyticsData;

  // const startingId = counts && Object.keys(counts)[0]
  
  const startingId = questions && questions[0] && questions[0].id;

  const [currentQuestionId, setCurrentQuestionId] = useState(startingId || 1);

  // useEffect(() => {
  //   setCurrentQuestionId(startingId || 1)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [analyticsData])

  useEffect(() => {
    setCurrentQuestionId(startingId || 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssessmentName, startingId])

  if (counts && !isLoading) {

    const currentQuestionDataObj = questions.find(({ id }) => String(id) === String(currentQuestionId)) || {};

    const currentQuestionCounts = counts[currentQuestionId];

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {
          Array.isArray(currentQuestionCounts) ?
            <TextAnswersGrid answers={currentQuestionCounts} />
            :
            <BarChart currentQuestionDataObj={currentQuestionDataObj} currentQuestionCounts={currentQuestionCounts} isPercentage={isPercentage} />
        }

        <QuestionSelector
          currentQuestionId={currentQuestionId}
          changeQuestionId={(changeQId) => setCurrentQuestionId(changeQId)}
          questions={questions}
        />
      </div>
    )
  } 
  else {
    return (
      <div style={{
        marginTop: '100px',
        flex: 1,
        display: 'flex',
        justifyContent: 'center'
      }}>
        <LoadingIcon />
      </div>
    )
  }
}