import React, { useState } from 'react';
import FeaturedReviewsContainer from './FeaturedReviewsContainer/FeaturedReviewsContainer';
import ProgramFeedbackSummary from './ProgramFeedbackSummary'
import AllProgramReviews from './AllProgramReviews/AllProgramReviews';
import { Section, SectionLabel } from 'prosolve-common-components-ui'

export default ({
  programData = {},
  type,
}) => {
  const {
    facilitatorReviews = [],
    studentReviews = []
  } = programData;

  const featuredReviews = type === 'facilitator' ? facilitatorReviews : studentReviews;

  const [showAllReviewsModal, setShowAllReviewsModal] = useState()

  const onShowAllReviews = () => {
    setShowAllReviewsModal(true)
  }

  return (
    <Section>
      {
        showAllReviewsModal &&
        <AllProgramReviews 
          programId={programData.id}  
          type={type}
          onClose={() => setShowAllReviewsModal(false)} 
        />
      }
      <SectionLabel>{type === 'facilitator' ? 'Highlighted Facilitator Reviews' : 'Highlighted Student Reviews' }</SectionLabel>
      <ProgramFeedbackSummary programData={programData} type={type} />
      {
        featuredReviews.length > 0 ?
        <>
        <FeaturedReviewsContainer reviews={featuredReviews} />
        <div 
          style={{
            color: '#0280c6',
            fontSize: '14px',
            fontWeight: 'bold',
            cursor: 'pointer',
            // marginLeft: '20px',
            marginTop: '20px',
            alignSelf: 'flex-end'
          }}
          onClick={onShowAllReviews}
        >
          {`View All ${type === 'facilitator' ? 'Facilitator' : 'Student'} Reviews`}
        </div>
        </>
        :
        <div style={{
          color: '#808080',
          fontSize: '14px',
          // marginLeft: '20px',
          marginTop: '20px',
          textAlign: 'center',
          width: '100%',
        }}>
          No reviews.
        </div>
      }
    </Section>
  )
}
