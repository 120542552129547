import React from 'react';
import moment from 'moment';
import AverageRatingBlock from '../EventSummary/AverageRatingBlock';
import { Button, Section } from 'prosolve-common-components-ui'
import { useGlobalData } from '../../GlobalData';
import { FaRegCalendar } from 'react-icons/fa';
import { CiExport } from 'react-icons/ci';

export default ({
  programData = {}
}) => {
  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  // console.log(programData)
  const {
    // name = '',
    title = '',
    averageReviewStars = '',
    programOverview = '',
    programImage = '',
    programStart = '',
    programEnd = '',
    accountName = '',
    eventDetailPageUrl = ''
  } = programData;

  // Getting rid of type for now
  // const type = 'TODO: Mobile Escape Room';
  // const account = accountName ? `for ${accountName}` : '';

  const formatDates = () => {

    const start = moment(programStart).format('MMM DD');
    const end = moment(programEnd).format('MMM DD');
    const year = moment(programEnd).format('YYYY');

    return `${start} - ${end}, ${year}`;
  }

  const formatOverview = () => {
    /* 
     * No risk since data comes from our salesforce data, but if we ever find we want to
     * do things in a different way, may want to investigate DomPurify:
     * 
     * https://stackoverflow.com/questions/27934238/rendering-raw-html-with-reactjs
     */ 
    return <div dangerouslySetInnerHTML={{__html: programOverview}} />
  }

  return (
    <Section>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: isMobileView ? 'column' : 'row',
          paddingBottom: '10px',
          marginBottom: '15px',
          borderBottom:  '1px solid #dedede'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: isMobileView ? '10px' : '0px'
            }}>
              <div style={{
                flex: 1,
                fontWeight: 'bold',
                fontSize: '16px',
                color: '#565656',
                display: 'flex',
                alignItems: 'center'
              }}>
                <FaRegCalendar style={{ marginRight: '5px'}} />
                {formatDates()}
              </div>
            </div>
            <div style={{
              fontSize: '34px',
              fontWeight: 'bold',
              marginTop: '5px'
            }}>
              {title}
            </div>
            <div style={{
              marginTop: '5px',
              // marginBottom: '10px',
              // marginLeft: '20px',
              fontSize: '15px',
              fontStyle: 'italic',
              color: '#404040'
            }}>
              {accountName}
            </div>
          </div>
          <div style={{ 
            flex: 1, 
            display: 'flex',
            justifyContent: isMobileView ? 'flex-start' : 'flex-end',
            marginTop: isMobileView ? '20px' : '0px',
          }}
          >
            <AverageRatingBlock stars={averageReviewStars} />
          </div>
        </div>
      <div style={{
        display: 'flex',
        flexDirection: isMobileView ? 'column' : 'row'
      }}>
        <div style={{ 
          flex: 4, 
          display: 'flex', 
          paddingRight: isMobileView ? '0px' : '20px',
          // alignItems: 'flex-end',
        }}>
          <img style={{ width: '100%', borderRadius: '5px' }} src={programImage} alt='programImage' />
        </div>
        <div style={{
          flex: 6,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div style={{
            flex: '1',
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: isMobileView ? '10px' : '0px',
          }}>
            <div style={{ lineHeight: '25px', fontSize: '17px' }}>
              {formatOverview()}
            </div>
          </div>
          {
            eventDetailPageUrl &&
            <div style={{ marginTop: '10px' }}>
              <Button style={{ display: 'inline-flex' }} onClick={() => window.open(eventDetailPageUrl, '_blank')}>
                <CiExport style={{ marginRight: '5px'}} />
                <span style={{ fontSize: '13px', marginTop: '2px' }}>Details</span>
              </Button>
            </div>
            }
        </div>
      </div>
      </div>
      {/* <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        
        <div style={{
           margin: '20px 0px',
           fontWeight: 'bold',
           fontSize: '17px',
           color: '#565656',
        }}>
          About the program
        </div>
        <div>{formatOverview()}</div>
      </div> */}
    </Section>
  )
}
