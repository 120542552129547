import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { Button, LoadingIcon, FullScreenLoadingCover, TextInput } from 'prosolve-common-components-ui'
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PreviewAssessment from './PreviewAssessment';
import MainEditor from './MainEditor';
import PublishConfirmationDialog from './PublishConfirmationDialog';
import Cookies from 'js-cookie';

export default () => {
  const fetch = useFetch();
  const navigate = useNavigate();

  const { assessmentName } = useParams();

  const adminPermissionsCookies = Cookies.get('adminPermissions') || '';
  const isReadOnly = !(adminPermissionsCookies.indexOf('ASSESSMENT_BUILDER_EDIT') > -1);

  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [assessment, setAssessment] = useState({});

  const [editedAssessment, setEditAssessment] = useState({})

  const [newAssessmentName, setNewAssessmentName] = useState('')

  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false)

  const { isDraft } = assessment;

  useEffect(() => {
    setEditAssessment({ ...assessment })
  }, [assessment])

  const [preview, setPreview] = useState()

  const onBackClicked = () => {
    navigate('/assessmentManager/builder');
  }

  const fetchAssessment = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`assessment/builder/getActiveOrDraftAssessment?name=${assessmentName}`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAssessment({})
      } else {
        setAssessment(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAssessment({})
      setIsLoading(false);
      // Roll back to builder home to handle case where we delete a draft of a brand new assessment
      // Revisit this logic if it causes other issues in regards to error handling
      navigate('/assessmentManager/builder');
    }
  }

  useEffect(() => {
    if (assessmentName && assessmentName !== 'new') {
      fetchAssessment()
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentName])

  const isValid = () => {
    if (assessmentName === 'new') {
      if (newAssessmentName && newAssessmentName !== 'new') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const performSaveAssessment = async () => {
      try {
        setIsSaving(true)

        const saveAssessmentData = {
          ...editedAssessment
        }

        if (assessmentName === 'new') {
          saveAssessmentData.name = newAssessmentName;
          saveAssessmentData.isNew = true;
        }

        const response = await fetch(`assessment/builder/update`, 
        { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: saveAssessmentData })
        })

        const jsonData = await response.json();

        if (jsonData.success) {
          if (assessmentName === 'new') {
            navigate(`/assessmentManager/builder/${newAssessmentName}`);
          } else {
            // Need to set the assessment we started from to the newly edited assessment to be our new latest and greatest
            fetchAssessment();
          }
        }

        setIsSaving(false)
      } catch (e) {
        setIsSaving(false)
      }
  }

  const onSaveAssessment = async () => {
    if (isValid()) {
      performSaveAssessment()
    }
  }

  const discardDraft = async () => {
    try {
      setIsSaving(true)

      const response = await fetch(`assessment/builder/discard`, 
      { 
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: editedAssessment })
      })

      const jsonData = await response.json();

      if (jsonData.success) {
        // Need to set the assessment we started from to the newly edited assessment to be our new latest and greatest
        fetchAssessment();
      }

      setIsSaving(false)
    } catch (e) {
      setIsSaving(false)
    }
  }

  const publishDraft = async () => {
    try {
      setIsSaving(true)

      const response = await fetch(`assessment/builder/publish`, 
      { 
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: editedAssessment })
      })

      const jsonData = await response.json();

      if (jsonData.success) {
        // Need to set the assessment we started from to the newly edited assessment to be our new latest and greatest
        fetchAssessment();
      }

      setIsSaving(false)
    } catch (e) {
      setIsSaving(false)
    }
  }

  const onSubmitPublish = () => {
    publishDraft();
    setIsPublishDialogOpen(false);
  }

  return (
    isLoading
      ?
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <LoadingIcon />
      </div>
      //
      // Below code example is model of how to get overflow auto on content below header
      // 
      // <div style={{
      //   flex: 1,
      //   display: 'flex',
      //   flexDirection: 'column',
      //   overflow: 'hidden'
      // }}>
      //   <div style={{ height: '40px', background: 'red' }}>Top</div>
      //   <div style={{ flex: 1, background: 'blue', overflow: 'auto' }}>
      //     <div style={{ height: '800px' }}>Content</div>
      //   </div>
      // </div>
      :
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          // added for now scrolling setup
          overflow: 'hidden'
        }}>
          {isSaving && <FullScreenLoadingCover /> }

          {
            Boolean(preview) &&
            <PreviewAssessment assessmentName={preview.assessmentName} previewData={editedAssessment} onClose={() => setPreview(undefined)} />
          }

          {
            Boolean(isPublishDialogOpen) &&
            <PublishConfirmationDialog
              onClose={() => setIsPublishDialogOpen(false)}
              onSubmit={onSubmitPublish}
            />
          }

          {/* Note: This extra flex container is needed in order for vertical scrolling to work correctly */}
          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            // added for now scrolling setup
            overflow: 'hidden'
          }}>

            <div style={{
              display: 'flex',
              borderBottom: '1px solid #c4c4c4',
              alignItems: 'center',
              padding: '10px 20px'
            }}>
              <FaArrowLeft onClick={() => onBackClicked()} style={{ fontSize: '22px', color: '#0280c6', marginRight: '20px', cursor: 'pointer' }} />
              <div style={{
                color: '#565656',
                fontSize: '24px',
                fontWeight: 'bold'
              }}>
                {
                  assessmentName === 'new' ?
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px' }}>Name:</span>
                    <TextInput
                      width='400px'
                      style={{  }}
                      value={newAssessmentName}
                      onChange={(val) => setNewAssessmentName(val)}
                      placeholder='Internal Name For this Assessment'
                    />
                  </div>
                  :
                  assessmentName
                }
                {
                  isDraft &&
                  <span style={{ color: '#7e7e7e', fontSize: '13px', fontStyle: 'italic', marginLeft: '3px', position: 'relative', top: '-6px' }}>
                    {'*Is Draft'}
                  </span>
                }
              </div>
              {assessmentName !== 'new' &&
                <Button
                  style={{ marginLeft: '20px', width: '250px' }}
                  $colored={true}
                  onClick={() => {
                    setPreview({ assessmentName })
                  }}
                >
                  {'Preview Assessment'}
                </Button>
              }
              <div style={{ flex: 1 }}>&nbsp;</div>
              {isDraft &&
                <Button
                  style={{ width: '150px', color: '#0280c6' }}
                  $disabled={isReadOnly}
                  onClick={() => {
                    if (!isReadOnly) {
                      discardDraft()
                    }
                  }}
                >
                  {'Discard Draft'}
                </Button>
              }
              <Button
                style={{ width: '250px', marginLeft: '10px' }}
                $colored={true}
                $disabled={isSaving || isReadOnly}
                onClick={() => {
                  if (!isSaving && !isReadOnly) {
                    onSaveAssessment()
                  }
                }}
              >
                {isDraft ? 'Save' : 'Save As Draft'}
              </Button>
              {
                isDraft &&
                <Button
                  style={{ marginLeft: '20px', width: '150px', color: '#0280c6' }}
                  $disabled={isReadOnly}
                  onClick={() => {
                    if (!isReadOnly) {
                      setIsPublishDialogOpen(true);
                    }
                  }}
                >
                  {'Publish'}
                </Button>
              }
            </div>

            {/* Added wrapper to scroll the main editor */}
            <div style={{ flex: 1, display: 'flex', overflow: 'hidden'}}>

              <MainEditor
                assessment={assessment}
                editedAssessment={editedAssessment}
                setEditAssessment={setEditAssessment}
                isReadOnly={isReadOnly}
              />

            </div>

          </div>

        </div>
  )
}