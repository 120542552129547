import React from "react";
import { FormField } from 'prosolve-common-components-ui'

export default ({
  formData
}) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '15px'
    }}>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <FormField
          style={{ flex: 1 }}
          fieldId='unitsDelivered'
          type='text'
          label='Units Delivered'
          formData={formData}
        />
        <FormField
          style={{ flex: 1 }}
          fieldId='shipmentCosts'
          type='currency'
          options={{ autoCommas: true }}
          label='Shipment Costs'
          formData={formData}
        />
      </div>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <FormField
          style={{ flex: 1 }}
          fieldId='shippedDate'
          type='date'
          label='Date of Shipment'
          formData={formData}
        />
        <FormField
          style={{ flex: 1 }}
          fieldId='trackingNumber'
          type='text'
          label='Tracking Number'
          formData={formData}
        />
      </div>
    </div>
  )
}