import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { TextAreaInput } from 'prosolve-common-components-ui'

const Option = styled.div`
  font-size: 14px;
  padding: 15px 20px;
  cursor: pointer;
  background: ${props => props.$selected ? "#d2275a" : ( props.$hideBorder ? "none": "#00000033" )};
  color: ${props => props.$selected ? "#ffffff" : "#ffffff"};
  text-align: left;
  border: ${props => props.$hideBorder ? "none" : "1px solid #949494" };
  border-radius: 5px;
  margin-bottom: 1px;
  &:hover {
    background:  ${props => props.$selected ? "#d2275a" : "#33333333!important;"};
    /* background: #33333333!important; */
    color: #ffffff!important;
  }
`;

export default ({
  questionId,
  type,
  answers,
  setAnswers,
  options = [],
  peformAutoNextAction,
  isRow,
}) => {
  const [textInputObj, setTextInputObj] = useState()
  const [clickAllowed, setClickAllowed] = useState(true);

  const answerObj =  answers[questionId] || {};
  const {
    answerArray = []
  } = answerObj;

  const preventDoubleClick = () => {
    setClickAllowed(false)
    setTimeout(() => {
      setClickAllowed(true)
    }, 310)
  }

  const onUpdateAnswer = (val, textInput) => {
    preventDoubleClick();
    let isRemoved = false;
    let updateAnswerArray = [...answerArray];
    const indexOfVal = updateAnswerArray.findIndex(({ id }) => id === val);
    if (indexOfVal > -1) {
      updateAnswerArray.splice(indexOfVal, 1);
      isRemoved = true;
    } else {
      if (type === 'radio') {
        updateAnswerArray = [{ id: val }];
      } else {
        updateAnswerArray.push({ id: val });
      }
    }
    
    const updateObj = { answerArray: updateAnswerArray }

    setAnswers({
      ...answers,
      [questionId]: updateObj
    })

    if (textInput) {
      setTextInputObj(isRemoved ? undefined : { id: val, text: textInput })
    } else {
      setTextInputObj(undefined)
    }

    if (!Boolean(textInput) && (type === 'radio' && updateAnswerArray.length > 0 && peformAutoNextAction)) {
      peformAutoNextAction();
    }
  }

  const currentOptionWithTextInput = textInputObj ? ( answerArray.find(({ id: aId }) => aId === textInputObj.id) || {} ) : {};

  const textInputRef = useRef();
  useEffect(() => {
    if (textInputObj && textInputObj.id && textInputRef && textInputRef.current) {
      const updateVal = currentOptionWithTextInput.answerText || '';
      textInputRef.current.setInput(updateVal);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textInputObj])

  const onUpdateAnswerWithTextInput = (id, value) => {
    let updateAnswerArray = [...answerArray];
    const currentAnswerObj = updateAnswerArray.find(({ id: aId }) => aId === id);

    if (currentAnswerObj) {
      currentAnswerObj.answerText = value;
    }

    const updateObj = { answerArray: updateAnswerArray }

    setAnswers({
      ...answers,
      [questionId]: updateObj
    })
  }

  const wrapperStyle = isRow ? { display: 'flex', justifyContent: 'center', columnGap: '10px' } : {}

  return (
    <>
    <div style={wrapperStyle}>
      {
        options.map(({ id, image, hideOptionText, answerOption, textInput }) => {
          return (
            <Option
              key={id}
              $selected={answerArray.findIndex(({ id: aId }) => aId === id) > -1}
              $hideBorder={isRow}
              onClick={() => { clickAllowed && onUpdateAnswer(id, textInput) }}
            >
              {
                image &&
                <img
                  src={image}
                  alt="answer-option"
                  style={{
                    height: '200px',
                    marginBottom: '20px'
                  }}
                />
              }
              {!hideOptionText && <div style={{ textAlign: isRow ? 'center' : 'left' }}>{answerOption}</div>}
            </Option>
          )
        })
      }
    </div>
    {
      textInputObj &&
      <div style={{ marginTop: '20px' }}>
        <div style={{ marginBottom: '5px' }}>{textInputObj.text}</div>
        <TextAreaInput
          ref={textInputRef} 
          width='100%'
          value={currentOptionWithTextInput.answerText}
          onChange={(val) => onUpdateAnswerWithTextInput(textInputObj.id, val)}
          placeholder=''
        />
      </div>
    }
    </>
  )
}