import React from 'react';
import { Modal } from 'prosolve-common-components-ui'

export default ({
  imageUrl = '',
  onClose,
}) => {
  return (
    <Modal isFullScreen={true} isDark={true} onClose={onClose}>
      <div style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <img
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
            borderRadius: '5px' 
          }} 
          src={imageUrl} 
          alt='programImageHighlight' 
        />
      </div>
    </Modal>
  )
}
