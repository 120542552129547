import React from 'react';
import { SelectDropdown, StarsDropdownFilter } from 'prosolve-common-components-ui'
import { useGlobalData } from '../../../GlobalData';

export default ({
  availableQuestions,
  onFiltersChanged,
}) => {
  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const selectQuestionOptions = availableQuestions.map(val => ({ value: val, text: val }))

  const onSelectedQuestionChanged = (question) => {
    onFiltersChanged({ question })
  }

  const onStarsChanged = (stars) => {
    onFiltersChanged({ stars })
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: isMobileView ? 'column' : 'row',
      gap: '20px 20px',
      // marginTop: hasStarsFilter ? '0px' : '20px',
      // justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      borderBottom:  '1px solid #dedede',
      marginBottom: '20px',      
    }}>
      <div style={{ flex: 1, width: isMobileView ? '100%' : 'auto' }}>
        <div style={{
          marginBottom: '5px',
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#565656',
        }}>
          Select Question
        </div>
        <SelectDropdown 
          id='select-review-question'
          width={'100%'}
          options={selectQuestionOptions}
          onChange={onSelectedQuestionChanged}
        />
      </div>
      <div style={{ flex: 1, width: isMobileView ? '100%' : 'auto' }}>
        <div style={{
          marginBottom: '5px',
          fontWeight: 'bold',
          fontSize: '14px',
          color: '#565656',
        }}>
          Filter By Rating
        </div>
        <StarsDropdownFilter 
          id='select-stars-question'
          width={'100%'}
          onStarsChanged={onStarsChanged} 
        />
      </div>
      {/* <StarsFilter onStarsChanged={onStarsChanged} /> */}
      {/* {
        hasStarsFilter &&
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{
            marginBottom: '5px',
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#565656',
          }}>
            Filter By Rating
          </div>
          <StarsDropdownFilter onStarsChanged={onStarsChanged} />
        </div>
      } */}
    </div>
  )
}
