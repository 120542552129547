import React, { useEffect, useState, useCallback } from 'react';
import { useGlobalData } from '../GlobalData';
import { Main, MainContent, Section, Footer, LoadingIcon, FadeInWrapper, FloatingDownButton } from 'prosolve-common-components-ui'
import ProgramDetails from './ProgramDetails/ProgramDetails';
import EventSummary from './EventSummary/EventSummary';
import ImageHighlights from './ImageHighlights/ImageHighlights';
import ProgramFeedback from './ProgramFeedback/ProgramFeedback';
// import ProgramMetrics from './ProgramMetrics/ProgramMetrics';
import useFetch from '../hooks/useFetch';

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default () => {
  const urlParams = new URLSearchParams(window.location.search);
  const programId = urlParams.get('id');

  const fetch = useFetch();

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const [programData, setProgramData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [noData, setNoData] = useState(false)

  const sectionElements = document.getElementsByClassName('section')

  const [hasFloatingButton, setHasFloatingButton] = useState(sectionElements.length > 1 ? true: false)

  // The below value acts as a buffer so we still scroll to next section within this value offset
  const scrollToSectionBuffer = 300

  useEffect(() => {
    if (sectionElements.length > 1) {
      setHasFloatingButton(true)
    } else {
      setHasFloatingButton(false)
    }
  }, [sectionElements, programData, noData])

  const onScrollEvent = useCallback(() => {
    const lastSectionElement = sectionElements[sectionElements.length - 1];
    if (lastSectionElement) {
      const lastSectionElementRect = lastSectionElement.getBoundingClientRect();
      if (lastSectionElementRect.top >= (window.innerHeight - scrollToSectionBuffer)) {
        setHasFloatingButton(true)
      } else {
        setHasFloatingButton(false)
      }
    }
  }, [sectionElements])

  useEffect(() => {
    window.addEventListener('scroll', onScrollEvent);
    return () => {
      window.removeEventListener('scroll', onScrollEvent);
    }
  }, [onScrollEvent])

  // Handy test program ids:

  // Use this for review data
  // const programId = 'a244z000009SiWSAA0';
  // Use this for trainers and sessions values
  // const programId = 'a244z000009NpDGAA0';
  // Use this test program for images
  // const programId = 'a244z000003mClTAAU';

  const fetchProgramData = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(`programs/program?id=${programId}`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setNoData(true)
      } else {
        setProgramData(jsonData)
      }
      
    } catch (e) {
      console.log(e)
      setNoData(true)
      setProgramData(undefined)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (programId) {
      fetchProgramData()
    } else {
      // Below is used for when url may be hit without an id provided in the url
      setIsLoading(false)
      setNoData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId])

  const onFloatingDownButtonClick = () => {
    // Find first element with a top measurement greater then window.innerHeight
    // This is possible since top for the element changes during scroll (and is negative when scroll up out of view)
    // Below out of view will always be when top is greater then window height
    let scrollToElement;
    for(let i = 0; i < sectionElements.length; i++) {
      const sectionElement = sectionElements[i];
      const sectionElementRect = sectionElement.getBoundingClientRect();
      if (sectionElementRect.top > (window.innerHeight - scrollToSectionBuffer)) {
        scrollToElement = sectionElement;
        break;
      }
    }

    if (scrollToElement) {
      scrollToElement.scrollIntoView();
    }
  }

  return (
    <Main $isMobileView={isMobileView}>
      <MainContent $isMobileView={isMobileView}>
        <div style={{ padding: '0px' }}>
        { 
          isLoading 
          ?
          <Section style={{ height: '1200px', alignItems: 'center' }}>
            <LoadingIcon />
          </Section>
          :
          noData ?
          <Section style={{ height: '400px' }}>
            Unable to find program data
          </Section>
          :
          <FadeInWrapper>
          <div>
            <ProgramDetails isMobileView={isMobileView} programData={programData} />
            <SectionDivider />
            <EventSummary programData={programData} />
            <SectionDivider />
            <ImageHighlights programData={programData} />
            <SectionDivider />
            <ProgramFeedback programData={programData} type='facilitator' />
            <SectionDivider />
            <ProgramFeedback programData={programData} type='student' />
            {/* <SectionDivider />
            <ProgramMetrics programData={programData} /> */}
          </div>
          </FadeInWrapper>
        }
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
      { !isMobileView && hasFloatingButton &&
        <FloatingDownButton onClick={onFloatingDownButtonClick} />
      }
    </Main>
  )
}
