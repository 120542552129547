import React, { useState, useEffect } from "react";
import ReviewsContainer from "./ReviewsContainer";
import { Modal, LoadingIcon } from 'prosolve-common-components-ui'
import useFetch from '../../../hooks/useFetch';

export default ({
  programId,
  type,
  onClose
}) => {
  const fetch = useFetch();

  const [isLoading, setIsLoading] = useState(true)
  const [reviews, setReviews] = useState([])

  const fetchAllReviews = async () => {
    setIsLoading(true)
    try {
      const typeParam = (type === 'facilitator' ? 'End User Feedback' : 'Student Feedback');
      const response = await fetch(`programs/program/reviews?id=${programId}&type=${typeParam}`, { method: 'GET' });
      const jsonData = await response.json();
      setReviews(jsonData)
    } catch (e) {
      console.log(e)
      setReviews([])
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAllReviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const title = (type === 'facilitator' ? 'Facilitator Reviews' : 'Student Reviews')

  return (
    <Modal title={title} noContentPadding={true} onClose={onClose}>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        {
          isLoading ?
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <LoadingIcon />
          </div>
          :
          reviews.length > 0 ?
          <ReviewsContainer reviews={reviews} hasStarsFilter={true} />
          :
          <div style={{
            color: '#808080',
            fontSize: '14px',
            marginTop: '20px'
          }}>
            No reviews yet for this program.
          </div>
        }
      </div>
    </Modal>
  )
}