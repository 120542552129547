import React from 'react';
import moment from 'moment';
import { Button } from 'prosolve-common-components-ui'
import { FaExternalLinkAlt } from 'react-icons/fa';

export default ({
  shipment,
  onConfirmShipmentClick
}) => {
  const {
    trackingNumber,
    shipmentStatus,
    createdDate,
    requestedDate,
    shippedDate,
    receivedDate,
    trackingUrl
  } = shipment;

  const formatTitle = () => {
    if (shipmentStatus === 'Waiting') {
      return 'ORDER CREATED';
    } else if (shipmentStatus === 'Request Shipment') {
      return 'SHIPMENT REQUESTED';
    } else if (shipmentStatus === 'Shipment Sent') {
      return 'SHIPMENT SENT';
    } else if (shipmentStatus === 'Shipment Completed') {
      return 'SHIPMENT COMPLETED';
    } else {
      return '';
    }
  }

  const getButtonText = () => {
    if (shipmentStatus === 'Waiting') {
      return 'REQUEST SHIPMENT';
    } else if (shipmentStatus === 'Request Shipment') {
      return 'FULFILL SHIPMENT REQUEST';
    } else if (shipmentStatus === 'Shipment Sent') {
      return 'CONFIRM SHIPMENT CONTENTS';
    } else if (shipmentStatus === 'Shipment Completed') {
      return 'VIEW RECEIPT';
    } else {
      return '';
    }
  }

  const formatDate = () => {
    let displayDate;
    if (shipmentStatus === 'Waiting') {
      displayDate = createdDate;
    } else if (shipmentStatus === 'Request Shipment') {
      displayDate = requestedDate;
    } else if (shipmentStatus === 'Shipment Sent') {
      displayDate = shippedDate;
    } else if (shipmentStatus === 'Shipment Completed') {
      displayDate = receivedDate;
    }

    return displayDate ? `on ${moment(displayDate).format('MM/DD/YYYY')}` : 'no date found';
  }

  const onOpenTrackingUrl = () => {
    window.open(trackingUrl, '_blank');
  }

  const buttonText = getButtonText();

  return (
    <div style={{
      background: '#f8f9fc',
      borderBottom: '1px solid #dedede',
      padding: '10px 20px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
      color: '#565656',
      fontWeight: 'bold'
    }}>
      <div style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center'
      }}>
        <div style={{ flex: 1 }}>
          <span style={{ color: '#080808', fontSize: '16px', marginRight: '5px' }}>{formatTitle()}</span>
          <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{formatDate()}</span>
        </div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        {
          (shipmentStatus === 'Shipment Sent' || shipmentStatus === 'Shipment Completed') &&
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <div>
              {`TRACKING ID: ${trackingNumber || ''}`}
            </div>
            <span onClick={onOpenTrackingUrl} style={{ color: '#0280c6', textDecoration: 'underline', fontWeight: 'normal', cursor: 'pointer', marginLeft: '10px' }}>
              {'Track package'}
            </span>
            <FaExternalLinkAlt onClick={onOpenTrackingUrl} style={{ fontSize: '12px', marginLeft: '5px', color: '#0280c6', cursor: 'pointer' }} />
          </div>
        }
        {
          Boolean(onConfirmShipmentClick) && Boolean(buttonText) &&
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              onClick={onConfirmShipmentClick} 
              $colored={true} 
              style={{ 
                height: '25px', 
                borderRadius: '20px',
              }}
            >
              {buttonText}
            </Button>
          </div>
        }
      </div>

    </div>
  )
}