import React, { useState } from 'react';
import ImagePlaceholder from '../../../images/image-placeholder.jpg'
import { Button } from 'prosolve-common-components-ui'
import { HiOutlineFlag } from 'react-icons/hi2';
import MissingItemForm from './MissingItemsForm'

export default ({
  item,
  missingItemsFormData,
  missingItemsFormDataIsReadOnly
}) => {
  const {
    name = '',
    programName = '',
    quantity = '',
    image = ''
  } = item;

  const imageSrc = image || ImagePlaceholder;

  const initIsMissingItemFormOpen = missingItemsFormData && missingItemsFormData.values && missingItemsFormData.values.missingNote;

  const [isMissingItemFormOpen, setIsMissingItemFormOpen] = useState(initIsMissingItemFormOpen)

  const onCloseForm = () => {
    if (missingItemsFormData && missingItemsFormData.values && missingItemsFormData.setValues)  {
      missingItemsFormData.setValues({ ...missingItemsFormData.values, missingNote: '' })
    }
    setIsMissingItemFormOpen(false)
  }

  return (
    <div style={{
      display: 'flex',
      color: '#565656',
      fontSize: '14px'
    }}>
      <img src={imageSrc} style={{ height: '70px', marginRight: '10px' }} alt=""/>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7px',
        marginTop: '2px',
        flex: 1
      }}>
        <div style={{ color: '#080808', fontSize: '16px'}}>{name}</div>
        <div>{programName}</div>
        <div>{`Quantity: ${quantity || ''}`}</div>
        {
          Boolean(missingItemsFormData) && (
          isMissingItemFormOpen
          ?
          <MissingItemForm missingItemsFormData={missingItemsFormData} missingItemsFormDataIsReadOnly={missingItemsFormDataIsReadOnly} onCloseForm={onCloseForm}  />
          :
          (
            !missingItemsFormDataIsReadOnly &&
            <Button style={{ width: '140px' }} onClick={() => setIsMissingItemFormOpen(true)}>
              <HiOutlineFlag style={{ marginRight: '5px', color: '#0280c6'}} />
              <span style={{ fontSize: '13px', marginTop: '2px' }}>Report Items Missing</span>
            </Button>
          )
          )
        }
      </div>
    </div>
  )
}