import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextInput } from 'prosolve-common-components-ui'

const Option = styled.div`
  font-size: 14px;
  padding: 15px 20px;
  color: ${props => props.$selected ? "#ffffff" : "#ffffff"};
  text-align: left;
  border-radius: 5px;
  margin-bottom: 1px;
`;

export default ({
  questionId,
  answers,
  setAnswers,
  options = []
}) => {
  const [hasInitialized, setHasInitialized] = useState(false)

  useEffect(() => {
    setHasInitialized(true)
  }, [])

  const answerObj =  answers[questionId] || {};
  const {
    answerArray = []
  } = answerObj;

  const onUpdateAnswer = (id, val) => {
    // We want to prevent this from being called on inital render
    if (hasInitialized) {
      let updateAnswerArray = [...answerArray];

      const textValObj = updateAnswerArray.find(({ id: aId }) => aId === id);

      if (textValObj) {
        textValObj.answerText = val;
      } else {
        updateAnswerArray.push({ id, answerText: val })
      }
      
      const updateObj = { answerArray: updateAnswerArray }

      setAnswers({
        ...answers,
        [questionId]: updateObj
      })
    }
  }

  const wrapperStyle = { display: 'flex', justifyContent: 'center', columnGap: '10px' };

  return (
    <div style={wrapperStyle}>
      {
        options.map(({ id, image, number }) => {
          const textValObj = answerArray.find(({ id: aId }) => aId === id) || {};
          const { answerText = '' } = textValObj;
          return (
            <Option key={id}>
              {
                image &&
                <img
                  src={image}
                  alt="answer-option"
                  style={{
                    height: '200px',
                    marginBottom: '20px'
                  }}
                />
              }
              <TextInput
                width='100%'
                style={{ textAlign: 'center' }}
                value={answerText}
                type={number ? 'number' : 'text' }
                min={0}
                onChange={val => onUpdateAnswer(id, val)}
                placeholder=''
              />
            </Option>
          )
        })
      }
    </div>
  )
}