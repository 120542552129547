import React, { useState } from 'react';
import GreenImage from '../images/team-icons/S2_ActiveTeam_Green.png';
import BlueImage from '../images/team-icons/S2_ActiveTeam_Blue.png';
import YellowImage from '../images/team-icons/S2_ActiveTeam_Yellow.png';
import PurpleImage from '../images/team-icons/S2_ActiveTeam_Purple.png';
import BlackImage from '../images/team-icons/S2_ActiveTeam_Black.png';
import RedImage from '../images/team-icons/S2_ActiveTeam_Red.png';
import { Button } from 'prosolve-common-components-ui'

export default ({
  onTeamColorSelected
}) => {

  const [selectedTeamColor, setSelectedTeamColor] = useState('')

  const colorImageMap = {
    Green: GreenImage,
    Blue: BlueImage,
    Yellow: YellowImage,
    Purple: PurpleImage,
    Black: BlackImage,
    Red: RedImage
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      background: `url("https://api.portal.questboxes.com/api/file/Product%20Images/apex-pre-survey-background.png") top left/cover no-repeat fixed`,
      overflow: 'auto'
    }}>
      <div style={{
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: '48px'
      }}>
        {'Select Your Team!'}
      </div>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '10px',
        columnGap: '10px',
        justifyContent: 'center',
        margin: '40px 0px'
      }}>
        {
          Object.keys(colorImageMap).map(color => {
            const colorImage = colorImageMap[color];
            return (
              <div 
                key={color}
                onClick={() => setSelectedTeamColor(color)}
                style={{
                  cursor: 'pointer',
                  border: selectedTeamColor === color ? '6px solid #56a83f' : '6px solid transparent',
                  borderRadius: '50%',
                  padding: '10px 2px 0px 2px'
                }}
              >
                <img src={colorImage} style={{ height: '200px' }} alt={`team-color-${color}`} />
              </div>
            )
          })
        }
      </div>
      <Button
        style={{
          display: 'inline-flex',
          marginRight: '10px',
          fontWeight: 'bold',
          fontSize: '24px',
          width: '150px',
          height: '40px',
          background: 'transparent'
        }}
        onClick={() => Boolean(selectedTeamColor) && onTeamColorSelected(selectedTeamColor)}
        $colored={true}
        $disabled={!Boolean(selectedTeamColor)}
      >
        {'SUBMIT'}
      </Button>
    </div>
  )
}