import React, { useEffect } from "react";
import useForm from '../../../hooks/useForm';
import { FormField } from 'prosolve-common-components-ui'

export default ({
  intro = {},
  backgroundImage,
  isReadOnly,
  onChange
}) => {
  const formSchema = {
    optionalFields: ['title', 'subTitle', 'buttonText', 'backgroundImage'],
    initData: { ...intro, backgroundImage }
  }

  const formData = useForm(formSchema)

  useEffect(() => {
    onChange(formData.values)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.values])

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '15px',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        columnGap: '15px',
        rowGap: '15px',
      }}>
        <FormField
          fieldId='title'
          type='text'
          label='Title'
          disabled={isReadOnly}
          style={{ flex: 1 }}
          formData={formData}
        />
        <FormField
          fieldId='buttonText'
          type='text'
          label='Button Text'
          disabled={isReadOnly}
          style={{ flex: 1 }}
          formData={formData}
        />
        <FormField
          fieldId='backgroundImage'
          type='text'
          label='Background Image'
          disabled={isReadOnly}
          style={{ flex: 1 }}
          formData={formData}
        />
      </div>
      <FormField
        fieldId='subTitle'
        type='textarea'
        label='Subtitle'
        disabled={isReadOnly}
        style={{ flex: 1 }}
        formData={formData}
      />
    </div>
  )
}