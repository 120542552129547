import React from 'react';
import moment from 'moment';
import { Stars } from 'prosolve-common-components-ui'

export default ({
  id,
  stars,
  createdDate,
  questionsAndAnswers = [],
  focusedQuestion,
  index
}) => {
  const formatDate = () => {
    const mmmmDD = moment(createdDate).format('MMMM DD');
    const year = moment(createdDate).format('YYYY');

    return `${mmmmDD}, ${year}`;
  }

  const questionAnswerOnly = Boolean(focusedQuestion);
 
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      backgroundColor: index % 2 === 0 ? '#f8f8f8' : '#ffffff',
    }}>
      {
        !questionAnswerOnly &&
        <>
        <Stars stars={stars} />
        <div style={{
          marginTop: '0px',
          color: '#808080',
          fontSize: '13px'
        }}>
          {formatDate()}
        </div>
        </>
      }
      <div>
        {
          questionsAndAnswers.map(({ question, answer }) => {
            if (questionAnswerOnly && question !== focusedQuestion) {
              return <React.Fragment key={`q-${id}-question-${question}`}></React.Fragment>
            }
            else {
              return (
                <div key={`q-${id}-question-${question}`}>
                  {
                    !questionAnswerOnly &&
                    <div style={{
                      marginTop: '10px',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      color: '#565656',
                      marginBottom: '2px',
                    }}>
                      {question}
                    </div>
                  }
                  <div style={{
                    fontSize: '16px',
                    color: '#565656',
                    lineHeight: '25px'
                  }}>
                    {/* <span style={{ marginRight: '1px' }}>"</span> */}
                    <span>{answer}</span>
                    {/* <span style={{ marginLeft: '1px' }}>"</span> */}
                  </div>
                </div>
              )
            }
          })
        }
      </div>
      {/* <div style={{
        color: '#0280c6',
        fontSize: '13px',
        fontWeight: 'bold',
        cursor: 'pointer'
      }}>
        View Full Review
      </div> */}
      {/* <div style={{
        width: '80%',
        height: '20px',
        borderBottom:  '1px solid #dedede'
      }}>
        &nbsp;
      </div> */}
    </div>
  )
}
