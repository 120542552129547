import React from 'react';
import { Checkbox, SelectDropdown } from 'prosolve-common-components-ui'

export default ({
  isPercentage, 
  setIsPercentage,
  hasProcessedAnalytics,
  processedAnalytics,
  setProcessedAnalytics,
  chartType,
  setChartType,
  chartTypes,
}) => {

  const chartTypeOptions = chartTypes.map(name => ({ text: name, value: name }))

  return (
    <div style={{
      color: '#565656',
      display: 'flex',
      padding: '20px',
      borderBottom: '1px solid #c4c4c4'
    }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ 
          color: '#565656',
          fontSize: '20px',
          fontWeight: 'bold',
          marginRight: '10px'
        }}>
          {'Chart Type:'}
        </div>
        <SelectDropdown
          id='select-chart-type'
          value={chartType}
          options={chartTypeOptions}
          onChange={(val) => setChartType(val)}
          width="150px"
        />
      </div>
      <div style={{ flex: 1 }}>&nbsp;</div>
      {hasProcessedAnalytics && <Checkbox label='Processed Analytics' value={processedAnalytics} onChange={() => setProcessedAnalytics(!processedAnalytics)} /> }
      <div style={{ marginRight: '10px' }}>&nbsp;</div>
      <Checkbox label='Show Percentage' value={isPercentage} onChange={() => setIsPercentage(!isPercentage)} />
    </div>
  )
}