import React, { useState, useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import { Accordion } from 'prosolve-common-components-ui'
import FullShipmentDetailsBlock from './FullShipmentDetailsBlock/FullShipmentDetailsBlock';
import ShipmentFilter from './ShipmentFilter';

export default ({
  shipments = [],
  updateShipmentData,
  forceTabCountRefresh
}) => {

  const [selectedTab, setSelectedTab] = useState('All')
  const [filters, setFilters] = useState({})
  const [appliedFilters, setAppliedFilters] = useState({})

  const timeoutRef = useRef();
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setAppliedFilters(filters)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const tabs = ['All', 'Waiting', 'Pending', 'In Progress', 'Completed']

  const statusToTabMap = {
    'Waiting': 'Waiting',
    'Request Shipment': 'Pending',
    'Shipment Sent': 'In Progress',
    'Shipment Completed': 'Completed'
  }

  const countsPerTab = useMemo(() => {
    const initCount = tabs.reduce((accumulator, currentValue) => ({ ...accumulator, [currentValue]: 0}), {});
    shipments.forEach(({ shipmentStatus = '' }) => {
      const shipmentTabValue = statusToTabMap[shipmentStatus];
      if (initCount[shipmentTabValue] !== undefined) {
        initCount[shipmentTabValue]++
      }
      initCount['All']++;
    });
    return initCount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipments, forceTabCountRefresh]);

  const dateFilterCheck = (value, fromDate, toDate) => {
    // We return true if value fails the check
    if (!value) {
      return true;
    }

    const startDate = moment(fromDate || '1970-01-01');
    const endDate = moment(toDate || '2099-01-01');

    const dayOnlyValue = moment(value).format('YYYY-MM-DD')

    const dateToCheck = moment(dayOnlyValue);

    // Check if the date falls between the start and end dates
    const isBetween = dateToCheck.isBetween(startDate, endDate, undefined, '[]'); // '[]' includes both start and end dates
    
    return isBetween ? false : true;
  }

  const filteredShipments = shipments.filter((shipment = {}) => {
    const { 
      shipmentStatus = '',
      itemName = '',
      contactName = '',
      contactEmail = '',
      createdDate,
      requestedDate,
      shippedDate,
      receivedDate
    } = shipment;

    let passedFilters = true;

    if (selectedTab && selectedTab !== 'All' && statusToTabMap[shipmentStatus] !== selectedTab) {
      passedFilters = false;
    }

    const {
      itemName: itemNameFilter,
      contactName: contactNameFilter,
      contactEmail: contactEmailFilter,
      createdDateFrom,
      createdDateTo,
      requestedDateFrom,
      requestedDateTo,
      shippedDateFrom,
      shippedDateTo,
      receivedDateFrom,
      receivedDateTo
    } = appliedFilters;

    if (itemNameFilter && itemName.replace(/ /g,'').replace('\t', '').toLowerCase().indexOf(itemNameFilter.replace(/ /g,'').replace('\t', '').toLowerCase()) < 0) {
      passedFilters = false;
    }


    if (contactNameFilter && (contactName || '').toLowerCase().indexOf(contactNameFilter.toLowerCase()) < 0) {
      passedFilters = false;
    }


    if (contactEmailFilter && (contactEmail || '').toLowerCase().indexOf(contactEmailFilter.toLowerCase()) < 0) {
      passedFilters = false;
    }

    if ((createdDateFrom || createdDateTo) && dateFilterCheck(createdDate, createdDateFrom, createdDateTo)) {
      passedFilters = false;
    }

    if ((requestedDateFrom || requestedDateTo) && dateFilterCheck(requestedDate, requestedDateFrom, requestedDateTo)) {
      passedFilters = false;
    }

    if ((shippedDateFrom || shippedDateTo) && dateFilterCheck(shippedDate, shippedDateFrom, shippedDateTo)) {
      passedFilters = false;
    }

    if ((receivedDateFrom || receivedDateTo) && dateFilterCheck(receivedDate, receivedDateFrom, receivedDateTo)) {
      passedFilters = false;
    }

    return passedFilters;
  })

  const filteredShipmentsByProjectMap = {};
  const projectNameToIdMap = {}

  filteredShipments.forEach((shipment) => {
    const { project = '', projectName = '' } = shipment;
    if (!filteredShipmentsByProjectMap[project]) {
      filteredShipmentsByProjectMap[project] = [];
    }
    if (!projectNameToIdMap[projectName]) {
      projectNameToIdMap[projectName] = project;
    }
    filteredShipmentsByProjectMap[project].push(shipment)
  });

  const projectNames = Object.keys(projectNameToIdMap).sort();

  const getShipmentSortDate = (shipment) => {
    const {
      shipmentStatus,
      createdDate,
      requestedDate,
      shippedDate,
      receivedDate
    } = shipment;

    let sortDate;
    if (shipmentStatus === 'Waiting') {
      sortDate = createdDate;
    } else if (shipmentStatus === 'Request Shipment') {
      sortDate = requestedDate;
    } else if (shipmentStatus === 'Shipment Sent') {
      sortDate = shippedDate;
    } else if (shipmentStatus === 'Shipment Completed') {
      sortDate = receivedDate;
    } else {
      sortDate = createdDate;
    }

    return sortDate;
  }

  const shipmentSortFn = (shipmentA, shipmentB) => {
    const shipSortDateA = getShipmentSortDate(shipmentA);
    const shipSortDateB = getShipmentSortDate(shipmentB);

    // return moment(shipSortDateB).valueOf() - moment(shipSortDateA).valueOf()

    return moment(shipSortDateB).diff(moment(shipSortDateA));
  }

  const sortShipments = (shipmentsToSort) => {
    let sortedShipments;
    if (selectedTab === 'All') {
      const shipmentGroups = {
        'Waiting': [],
        'Pending': [],
        'In Progress': [],
        'Completed': []
      }

      const otherGroups = [];
      
      shipmentsToSort.forEach(shipmentObj => {
        const { shipmentStatus = '' } = shipmentObj;
        const shipmentTabValue = statusToTabMap[shipmentStatus];
        if (shipmentGroups[shipmentTabValue]) {
          shipmentGroups[shipmentTabValue].push(shipmentObj)
        } else {
          otherGroups.push(shipmentObj)
        }
      });

      sortedShipments = [
        ...shipmentGroups['Waiting'].sort(shipmentSortFn),
        ...shipmentGroups['Pending'].sort(shipmentSortFn),
        ...shipmentGroups['In Progress'].sort(shipmentSortFn),
        ...shipmentGroups['Completed'].sort(shipmentSortFn),
        ...otherGroups
      ]


    } else {
      sortedShipments = shipmentsToSort.sort(shipmentSortFn)
    }

    return sortedShipments;
  }

  return (
    <div>
      <ShipmentFilter
        tabs={tabs}
        countsPerTab={countsPerTab}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        filters={filters}
        setFilters={setFilters}
      />
      {
        projectNames.length > 0 ?
        projectNames.map((projectName) => {
          const project = projectNameToIdMap[projectName];
          const shipmentsInProject = sortShipments(filteredShipmentsByProjectMap[project]);

          const projectNameFormatted = projectName || 'Shipments without program';
          
          return (
            <Accordion
              key={projectNameFormatted}
              title={<div style={{ fontWeight: 'bold' }}>{projectNameFormatted}</div>}
              defaultOpen={true}
              headerExpands={true}
              style={{ padding: '20px 5px 20px 5px' }}
              expandedStyle={{ padding: '0px 0px 10px 0px' }}
            >
              {
                shipmentsInProject.map((shipment) => {
                  return (
                    <FullShipmentDetailsBlock key={shipment.id} shipment={shipment} updateShipmentData={updateShipmentData} />
                  )
                })
              }
            </Accordion>
          )
        })
        :
        <div style={{ marginTop: '20px', marginBottom: '10px', marginLeft: '10px' }}>
          No shipments found via selected filters
        </div> 
      }
    </div>
  )
}